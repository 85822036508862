var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('boolSwitch',{staticStyle:{"position":"absolute","top":"216px","left":"36%"},attrs:{"fieldAttributes":{ name: _vm.language.rttStep.useFirstDNS, type: 'boolSwitch'},"field":_vm.field,"rules":_vm.rules,"value":_vm.valueArray.useFirstDnsResultOnly,"templateContent":_vm.result,"fieldAttrInput":{ id:'use_first_DNS_result_only'}},on:{"input":function($event){return _vm.updateFieldValue('useFirstDnsResultOnly', ...arguments)}}}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('defaultFieldTypes',{attrs:{"fieldAttributes":{
          name: _vm.language.rttStep.targetHost,
          type: 'textarea',
          fieldAttrInput:  { rows: 8, class: 'multiLineIPwithDomains multiLineIPwithDomainsWithVariables required ' },
        },"templateContent":_vm.result,"value":_vm.valueArray.targets,"isValid":_vm.isValid,"hideDetails":true,"field":"targets"},on:{"input":function($event){return _vm.updateFieldValue('targets', ...arguments)}}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('defaultFieldTypes',{attrs:{"fieldAttributes":{
          name: _vm.language.rttStep.resolveHost,
          type: 'textarea',
          fieldAttrInput: _vm.fieldAttrInputDNS,
        },"templateContent":_vm.result,"value":_vm.valueArray.dnsServer,"isValid":_vm.isValid,"hideDetails":false,"field":"dnsServer"},on:{"input":function($event){return _vm.updateFieldValue('dnsServer', ...arguments)}}}),_c('boolSwitch',{attrs:{"fieldAttributes":{  name: _vm.language.rttStep.includeDNS,
                                     type: 'boolSwitch'
                                  },"field":_vm.field,"rules":_vm.rules,"value":_vm.valueArray.useDefaultDnsServer,"templateContent":_vm.result,"fieldAttrInput":{ id:'use_default_DNS_server'}},on:{"input":function($event){return _vm.updateFieldValue('useDefaultDnsServer', ...arguments)}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }