<template>
  <div>
    <boolSwitch
        style="position: absolute;top: 216px;left: 36%;"
        :fieldAttributes="{ name: language.rttStep.useFirstDNS, type: 'boolSwitch'}"
        :field="field"
        @input="updateFieldValue('useFirstDnsResultOnly', ...arguments)"
        :rules="rules"
        :value="valueArray.useFirstDnsResultOnly"
        :templateContent="result"
        :fieldAttrInput="{ id:'use_first_DNS_result_only'}"
    ></boolSwitch>
    <v-row>
      <v-col cols="6">
        <defaultFieldTypes
          :fieldAttributes="{
            name: language.rttStep.targetHost,
            type: 'textarea',
            fieldAttrInput:  { rows: 8, class: 'multiLineIPwithDomains multiLineIPwithDomainsWithVariables required ' },
          }"
          @input="updateFieldValue('targets', ...arguments)"
          :templateContent="result"
          :value="valueArray.targets"
          :isValid="isValid"
          :hideDetails="true"
          field="targets"
        ></defaultFieldTypes>
      </v-col>
      <v-col cols="4">
        <defaultFieldTypes
          :fieldAttributes="{
            name: language.rttStep.resolveHost,
            type: 'textarea',
            fieldAttrInput: fieldAttrInputDNS,
          }"
          @input="updateFieldValue('dnsServer', ...arguments)"
          :templateContent="result"
          :value="valueArray.dnsServer"
          :isValid="isValid"
          :hideDetails="false"
          field="dnsServer"
        ></defaultFieldTypes>
         <!-- <defaultFieldTypes
          :fieldAttributes="{
            name: 'Include DNS servers configured in the processing test system',
            type: 'boolSwitch',
            fieldAttrInput:{ class: 'required' }
          }"
          :fieldAttrInput="{ class: 'required' }"
          @input="updateFieldValue('useDefaultDnsServer', ...arguments)"
          :templateContent="result"
          :value="valueArray.useDefaultDnsServer"
          :isValid="isValid"
          :hideDetails="false"
          field="useDefaultDnsServer"
          :rules="rules"
        ></defaultFieldTypes>-->
           <boolSwitch
                  :fieldAttributes="{  name: language.rttStep.includeDNS,
                                       type: 'boolSwitch'
                                    }"
                  :field="field"
                  @input="updateFieldValue('useDefaultDnsServer', ...arguments)"
                  :rules="rules"
                  :value="valueArray.useDefaultDnsServer"
                  :templateContent="result"
                  :fieldAttrInput="{ id:'use_default_DNS_server'}"
          ></boolSwitch>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
import boolSwitch from "@/commonComponents/boolSwitch.vue";

export default {
  components: {
    defaultFieldTypes,
     boolSwitch
  },
  data() {
    return {
      //fieldAttrInputHost: { rows: 8, class: 'multiLineIPwithDomains multiLineIPwithDomainsWithVariables required ' },
      fieldAttrInputDNS:{ class: 'multiLineIPsWithVariables'}
    };
  },
  props: [
    "field",
    "fieldAttributes",
    "result",
    "value",
    "valueLine",
    "isValid",
  ],
  created() {
    // this.fieldAttrInputHost;
     this.fieldAttrSwitch;
     this.fieldAttrInputDNS;
     this.updateRequired();
     this.$nextTick(function () {
      this.$emit("revalidate");
    });
  },
  watch: {
    "valueLine.target": function () {
        this.updateRequired();
      this.$emit("revalidate");
     },
  },
  computed: {
    rules() {
      let rules = [];
      rules.push(this.checkCombi(this.switchClicked,this.selected) || '' );
      return rules;
    },
    selected(){
     let result =0;
     if(typeof this.valueLine[this.field]['dnsServer']!='undefined' &&
       this.valueLine[this.field]['dnsServer']!=''){
       result =  this.valueLine[this.field]['dnsServer'];
     }
     return result;
   },
    switchClicked(){
      let result =false;
      if(this.valueLine[this.field]['useDefaultDnsServer']==true){
        result = this.valueLine[this.field]['useDefaultDnsServer'];
      }
      return result;
    },
    valueArray() {
      let valueArray = {};
      let targets = this.value.targets.join("\n");
      let dnsServer = this.value.dnsServer.join("\n");
      valueArray = {
        ...this.value,
        targets: targets,
        dnsServer: dnsServer,
      };
      return valueArray;
    },
  },
  methods: {
    checkCombi(switchVal,selected){
      let result= false;
      if(switchVal==false && (typeof selected=='undefined' ||  (typeof selected!='undefined' && selected.length==0))){
        result= false;
      }else if(switchVal==true){
        result= true;
      }else if(typeof selected!='undefined' && selected.length>0){
        result= true;
      }
      return result;
    },

    updateRequired(){
      //console.log(this.valueLine[this.field]['useDefaultDnsServer']);
       if(this.valueLine[this.field]['useDefaultDnsServer']==false ){
        this.fieldAttrInputDNS = { class: 'multiLineIPsWithVariables required' }
       }else {
         this.fieldAttrInputDNS = { class: 'multiLineIPsWithVariables' }
       }/*else if(typeof this.valueLine[this.field]['dnsServer']!='undefined' &&
        this.valueLine[this.field]['dnsServer']!='' &&
        this.valueLine[this.field]['dnsServer'].length >0 ){
        this.fieldAttrSwitch = { class: ''}
        }*/
      this.$emit("revalidate");
    },
    updateFieldValue(index, value) {
      let localValue = { ...this.value };
      if (index == "targets" || index == "dnsServer") {
        let valueArray = value.split("\n");
        this.$set(localValue, index, valueArray);
        this.$emit("input", localValue);
      } else if (index != "ip") {
        this.$set(localValue, index, value);
        this.$emit("input", localValue);
      } else if (index == "ip") {
        this.$set(this.valueLine, index, value);
        this.$emit("stored-value-changed", this.valueLine);
      }
      this.$emit("revalidate");
    },
  },
};
</script>